import React from 'react';
import { Link, matchPath } from 'react-router-dom';
import './Home2Navigation.css';

class Home2Navigation extends React.Component {

  topNavClass = "home2nav";

  onBurgerClick() {
    if (this.topNavClass === "home2nav") {
      this.topNavClass = "home2nav responsive";
    } else {
      this.topNavClass = "home2nav";
    }
  }

  render() {

    var liveMapLinkClass = matchPath(window.location.pathname, { path: '/', exact: true, strict: true }) != null ? "active" : "link";
    var technologyLinkClass = matchPath(window.location.pathname, { path: '/technology', }) != null ? "active" : "link";
    var contactUsLinkClass = matchPath(window.location.pathname, { path: '/contactus', }) != null ? "active" : "link";
    var whirlOffLinkClass = matchPath(window.location.pathname, { path: '/whirloff', }) != null ? "active" : "exitLink";

    return (
      <div width="100%">
        <div className={this.topNavClass}>
          <Link to={''} className={liveMapLinkClass}><img src="/img/globe.png" alt="globe" height="20" style={{ "verticalAlign": "top" }} />&nbsp;&nbsp;Live Wind Map</Link>
          <a href='https://www.upminsterwindmill.org/smartmolen' className={technologyLinkClass}>Technology Overview</a>
          <a href='https://www.upminsterwindmill.org/contact' className={contactUsLinkClass}>Contact Us</a>
          <a href='/whirloff' className={whirlOffLinkClass}><img src="img/whirloff-icon.svg" alt="whirling windmill" height="20" style={{ "verticalAlign": "middle" }} /> Windmill Whirl-Off</a>
          <a href="# " className="icon" onClick={ev => { this.onBurgerClick(); }}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
      </div>
    );
  }
}

export default Home2Navigation