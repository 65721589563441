class UnitLocalisationLogic {

    getLocalisedWindSpeed(speedMs, context) {
        var windSpeed = { value: speedMs, units: "m/s" };

        if (context.split('|')[0] === "european") {
            windSpeed.units = "km/h";
            windSpeed.value = Math.round(speedMs * 3.6);
        }
        else {
            windSpeed.units = "mph";
            windSpeed.value = Math.round(speedMs * 2.23694);
        }

        return windSpeed;
    }

    getLocalisedSailRotationSpeed(rpm, context) {
        var sailRotationSpeed = { value: (Math.round(rpm * 10) / 10), units: "rpm", unitLocalisationKey: "rpm" };

        if (context.split('|')[1] === "ends") {
            sailRotationSpeed.units = "ends/min";
            sailRotationSpeed.unitLocalisationKey = "endsPerMin";
            sailRotationSpeed.value = Math.round(rpm * 4);
        }

        return sailRotationSpeed;
    }

    getLocalisedSailRotationSpeedFactoringNumberOfSails(rpm, numberOfSails, context) {
        var sailRotationSpeed = { value: (Math.round(rpm * 10) / 10), units: "rpm", unitLocalisationKey: "rpm" };

        if (context.split('|')[1] === "ends") {
            sailRotationSpeed.units = "ends/min";
            sailRotationSpeed.unitLocalisationKey = "endsPerMin";
            sailRotationSpeed.value = Math.round(rpm * numberOfSails);
        }

        return sailRotationSpeed;
    }


    getDaysOfWeekFirstLetters(context) {

        var daysOfWeekFirstLetters = ["M", "T", "W", "T", "F", "S", "S"];
        if (context.split('|').length >= 2) {
            if (context.split('|')[2] === "nl") {
                daysOfWeekFirstLetters = ["M", "D", "W", "D", "V", "Z", "Z"];
            }
            else
                if (context.split('|')[2] === "de") {
                    daysOfWeekFirstLetters = ["M", "D", "M", "D", "F", "S", "S"];
                }
        }

        return daysOfWeekFirstLetters;

    }

}

export default UnitLocalisationLogic

