import React from 'react';
import './WhirlOffHeadlineStats.css';
import Odometer from 'react-odometerjs';
import { FormattedMessage } from 'react-intl';
import WhirlOffWeekdaySparkBars from '../WhirlOffWeekdaySparkBars/WhirlOffWeekdaySparkBars';
import moment from 'moment';
import UnitLocalisationLogic from '../../components/unitlocalisationLogic';
import UnitsContext from '../../UnitsContext';

class WhirlOffHeadlineStats extends React.Component {

    static contextType = UnitsContext;

    componentDidMount() {
    }

    render() {

        var grandTotal = this.props.whirloff.reduce((n, { revCountThisYear }) => n + revCountThisYear, 0);
        var dailyStatsTotal = [0, 0, 0, 0, 0, 0, 0];

        for (let i = 0; i < 7; i++) {
            for (let j = 0; j < this.props.whirloff.length; j++) {
                dailyStatsTotal[i] = dailyStatsTotal[i] + this.props.whirloff[j].annualStats.rotationCountsByDayOfWeek[i];
            }
        }

        var millWithHighestDailyCount = this.props.whirloff.reduce(
            (prev, current) => {
                return prev.annualStats.highestDailyRotationCount > current.annualStats.highestDailyRotationCount ? prev : current
            }
        );

        var millsWithAnnualWeatherData = this.props.whirloff.filter(e => e.annualWeatherStats != null);
        var millWithStrongestRecordedWindGust = millsWithAnnualWeatherData.reduce(
            (prev, current) => {
                return prev.annualWeatherStats.highestObservedWindGust.windGustMs > current.annualWeatherStats.highestObservedWindGust.windGustMs ? prev : current
            }
        );

        var localisedWindSpeed = null;
        if (millWithStrongestRecordedWindGust != null) {
            localisedWindSpeed = new UnitLocalisationLogic().getLocalisedWindSpeed(millWithStrongestRecordedWindGust.annualWeatherStats.highestObservedWindGust.windGustMs, this.context)
        }

        return (
            <>
                <div className="whirloff-headline-stats-container">
                    {this.props.mode === "long" &&
                        <>
                            <div className="whirloff-headline-stats-title"> <FormattedMessage
                                id="whirloff.headlineStats.yearInNumbers"
                                defaultMessage="{year} in numbers"
                                values={{ year: this.props.year }} /></div>
                        </>
                    }
                    <div className="whirloff-grand-total">
                        <div className="whirloff-headline-stats-major-stat">
                            <Odometer value={grandTotal} format="(,ddd)" theme="minimal" />
                        </div>
                        <FormattedMessage
                            id="whirloff.headlineStats.sailRevolutionSubtitle"
                            defaultMessage="sail revolutions counted" />
                    </div>
                    {this.props.mode === "long" && <>
                        <div className="whirloff-stats-row">
                            <div className="whirloff-stat-left" >
                                <div className="whirloff-headline-stats-major-stat">{this.props.whirloff.length}</div>
                                <FormattedMessage
                                    id="whirloff.headlineStats.windmillCountSubtitle"
                                    defaultMessage="windmills" />
                            </div>
                            <div className="whirloff-stat-right">
                                <div className="whirloff-headline-stats-major-stat">3</div>
                                <div className="whirloff-grand-total-windmill-count" ><FormattedMessage
                                    id="whirloff.headlineStats.countryCountSubtitle"
                                    defaultMessage="countries" /></div>
                            </div>
                        </div>
                        <div className="whirloff-grand-total">
                            <div className="whirloff-headline-stats-secondary-title">
                                <FormattedMessage
                                    id="whirloff.featuredMill.stats.weekdays"
                                    defaultMessage="Weekdays" />
                            </div>
                            <WhirlOffWeekdaySparkBars barWidth={24} barHeight={48} countsByWeekday={dailyStatsTotal} />
                        </div>

                        {millWithHighestDailyCount.annualStats.highestDailyRotationCount > 0 && <>
                            <div className="whirloff-grand-total">
                                <div className="whirloff-headline-stats-secondary-title">
                                    <FormattedMessage
                                        id="whirloff.headlineStats.mostTurnsInOneDay"
                                        defaultMessage="Most turns in one day" />
                                </div>
                                <div className="whirloff-headline-stats-secondary-detail">
                                    {millWithHighestDailyCount.name}<br />
                                    {millWithHighestDailyCount.annualStats.highestDailyRotationCount}&nbsp;turns on&nbsp;
                                    {moment(millWithHighestDailyCount.annualStats.highestDailyRotationCountDate).format("DD MMM YYYY")}
                                </div>
                            </div>
                        </>
                        }

                        {millWithStrongestRecordedWindGust != null && <>
                            <div className="whirloff-grand-total">
                                <div className="whirloff-headline-stats-secondary-title">
                                    <FormattedMessage
                                        id="whirloff.headlineStats.highestReportedWindSpeed"
                                        defaultMessage="Strongest Reported Wind Gust" />
                                </div>
                                <div className="whirloff-headline-stats-secondary-detail">
                                    {millWithStrongestRecordedWindGust.name}<br />
                                    {localisedWindSpeed.value}&nbsp;{localisedWindSpeed.units}&nbsp;
                                    {millWithStrongestRecordedWindGust.annualWeatherStats.highestObservedWindGust.windDirectionCompassPoint} at&nbsp;
                                    {moment(millWithStrongestRecordedWindGust.annualWeatherStats.highestObservedWindGust.utcTime).format("HH:mm DD MMM YYYY")}
                                </div>
                            </div>
                        </>
                        }

                    </>
                    }
                </div>
            </>
        )
    }
}

export default WhirlOffHeadlineStats