import React from 'react'
import './Home2.css'
import Home2Header from './Home2Header'
import Home2Footer from './Home2Footer'
import Home2MapHeaderContent from './Home2MapHeaderContent'
import Home2MapBoundsLinks from './Home2MapBoundsLinks'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import MolenListItem from '../components/molenList/molenListItem/molenListItem'
import { Breakpoint, BreakpointProvider } from 'react-socks';

class Home2 extends React.Component {

  constructor(props) {
    super();
    this.onMapMillSelect = this.onMapMillSelect.bind(this);
  }

  state = {
    onClickBackButton: false,
    mapZoom: 6,
    mapLat: 52.8,
    mapLong: -1.5,
    selectedMolen: "upminster",
    mapMolenFocus: "",
    name: null,
    displaySequence: 0,
    displaySequenceIntervalId: 0
  };

  reloadRequested = true;
  lastLogTimeMillis = new Date().getTime();

  onMapMillSelect(entry) {
    try {
      var smartmolenSelectionInstruction = JSON.parse(entry.data);
      console.log("map mill select " + smartmolenSelectionInstruction.selectedMolen);

      if (typeof (smartmolenSelectionInstruction.selectedMolen) !== 'undefined') {
        this.setState({ selectedMolen: smartmolenSelectionInstruction.selectedMolen });
      }
    }
    catch (e) {
      // do nothing - miscellaneous messages may be picked up which will fail JSON parse
    }
  }


  componentDidMount() {

    // these lines are part of the logic to reload the page when the back button is pressed
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onClickBackButton);

    window.addEventListener("message", this.onMapMillSelect);

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          if (this.reloadRequested === false) {
            this.reloadRequested = true;
            setTimeout(this.reloadMolens(), 3000);
          }
        });

    });

    this.reloadMolens();

    // cycle the "display sequence" from 0 to 3 on a schedule
    const newIntervalId = setInterval(() => {
      this.setState(prevState => {
        return {
          ...prevState,
          displaySequence: ((prevState.displaySequence + 1) % 4),
        };
      });
    }, 5000);

    this.setState(prevState => {
      return {
        ...prevState,
        displaySequenceIntervalId: newIntervalId,
      };
    });
  }


  // this function is part of the logic to reload the page when the back button is pressed
  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.onClickBackButton) {

      this.onClickBackButton = true;
      this.reloadRequested = true;
      this.reloadMolens();
      window.history.push();
    }
  }

  // this function is part of the logic to reload the page when the back button is pressed
  componentWillUnmount = () => {
    window.removeEventListener('popstate', this.onClickBackButton);
    clearInterval(this.state.displaySequenceIntervalId);
  }


  reloadMolens() {

    this.reloadRequested = false;

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molenList`)
      .then(response => response.json())
      .then(response => {
        var molenListSorted = response;
        molenListSorted.sort(function (a, b) {
          var aPoints = a.webcam === null ? 0 : 8;
          var bPoints = b.webcam === null ? 0 : 8;
          if (a.latestSailRotationReading != null) {aPoints += 4;}
          if (b.latestSailRotationReading != null) {bPoints += 4;}
          if (a.latestFantailSensorReading != null) {aPoints += 2;}
          if (b.latestFantailSensorReading != null) {bPoints += 2;}
          if (a.latestOrientationSensorReading != null) {aPoints +=1;}
          if (b.latestOrientationSensorReading != null) {bPoints +=1;}
          return bPoints - aPoints;
        })
        this.setState({
          molenList: molenListSorted,
        })

      });

    // log in 'plausible' that the window is still open (every 30 mins)
    if (this.lastLogTimeMillis <= (new Date().getTime()) - 1800000) {
      window.plausible('homeLive');
      this.lastLogTimeMillis = new Date().getTime();
    }
  }

  focusMapOnMolen(molen) {
    this.setState({ "selectedMolen": molen.shortName });
    document.getElementById('windy-iframe').contentWindow.postMessage({ 'messageType': 'focus', 'molenShortName': molen.shortName });
  }

  setMapBounds(bounds) {
    document.getElementById('windy-iframe').contentWindow.postMessage({ 'messageType': 'bounds', 'bounds': bounds });
  }

  render() {
    document.title = "smartmolen";
    if (this.state == null || this.state.molenList == null) {
      return (
        <div className="home2">
        </div>
      )
    }
    else {
      return this.renderLoaded();
    }
  }

  renderLoaded() {

    // TODO: sort appropriately
    var molenList = this.state.molenList;

    return (
      <>
        <BreakpointProvider>
          <Breakpoint xlarge up>
            <div className="home2-full">
              <div className="home2-header-full"><Home2Header /></div>
              <div className="home2-map-full-xlarge">
                <div className="home2-map-bounds-links-full"><Home2MapBoundsLinks onBoundsSelected={this.setMapBounds.bind(this)} /></div>
                <div className="home2-windy-map-full">
                  <iframe id="windy-iframe" src={"/map/map.html?bounds=nweurope&molenFocus=" + this.state.mapMolenFocus} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              </div>
              <div className="home2-content-right-xlarge">
                <div className="home2-list-header-full"><Home2MapHeaderContent molenList={this.state.molenList} /></div>
                <div className="home2-scroll-full-xlarge">
                  <div className="home2-molen-table-full">
                    {molenList.map((molen, index) => (
                      <MolenListItem key={molen.molenId} molen={molen} onMolenSelected={this.focusMapOnMolen.bind(this)} displaySequence={this.state.displaySequence} selected={this.state.selectedMolen === molen.shortName} />
                    ))}
                  </div>
                </div>
                <div className="home2-footer-full"><Home2Footer /></div>
              </div>
            </div>
          </Breakpoint>

          <Breakpoint large only>
            <div className="home2-full">
              <div className="home2-header-full"><Home2Header /></div>
              <div className="home2-map-full-large"><iframe id="windy-iframe" src={"/map/map.html?bounds=nweurope&molenFocus=" + this.state.mapMolenFocus} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              <div className="home2-content-right-large">
                <div className="home2-list-header-full"><Home2MapHeaderContent molenList={this.state.molenList} /></div>
                <div className="home2-scroll-full-large">
                  <div className="home2-molen-table-full">
                    {this.state.molenList.map((molen, index) => (
                      <MolenListItem key={molen.molenId} molen={molen} displaySequence={this.state.displaySequence} onMolenSelected={this.focusMapOnMolen.bind(this)} />
                    ))}
                  </div>
                </div>
                <div className="home2-footer-full"><Home2Footer /></div>
              </div>
            </div>
          </Breakpoint>

          <Breakpoint medium down>
            <div className="home2-scroll">
              <div className="home2-header-mobile"><Home2Header /></div>
              <div className="home2-list-header"><Home2MapHeaderContent molenList={this.state.molenList} /></div>
              <div className="home2-map-inline"><iframe id="windy-iframe" src={"/map/map.html?bounds=nweurope&molenFocus=" + this.state.mapMolenFocus} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              <div className="home2-molen-table">
                {this.state.molenList.map((molen, index) => (
                  <MolenListItem key={molen.molenId} molen={molen} displaySequence={this.state.displaySequence} onMolenSelected={this.focusMapOnMolen.bind(this)} />
                ))}
                <div className="home2-footer"><Home2Footer /></div>
              </div>
            </div>
          </Breakpoint>
        </BreakpointProvider>
      </>
    )
  }
}



export default Home2