import React from 'react';
import './ResponseToWindPanel.css';
import moment from 'moment';
import {beaufortColorScale} from '../../components/beaufortColorScale/beaufortColorScale';

var responseToWindAnalysis;

class ResponseToWindPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.updateAnalysis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.molen.analysis.molenResponseToWindAnalysisId !== this.props.molen.analysis.molenResponseToWindAnalysisId) {
            this.updateAnalysis();
        }
    }

    updateAnalysis() {
        if (this.props.molen != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/analysis/responseToWind/` + this.props.molen.analysis.molenResponseToWindAnalysisId)
                .then(response => response.json())
                .then(response => {
                    responseToWindAnalysis = response;
                    this.setState({ analysisLoadTime: new Date() })
                });
        }
    }

    render() {
        if (this.props == null || this.props.molen == null || this.state.analysisLoadTime == null || responseToWindAnalysis == null || responseToWindAnalysis.responseByWindSpeed == null) {
            return <></>
        }
        else {
            return this.renderLoaded();
        }
    }

    pickHex(color1, color2, weight) {
        var w1 = weight;
        var w2 = 1 - w1;
        var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
        return rgb;
    }

    getCellBackgroundColor(response) {
        var anticlockwiseColor = [21, 102, 173];
        var clockwiseColor = [12, 176, 165];
        var noMovementColor = [255, 255, 255];
        if (response < -4) { return anticlockwiseColor; }
        if (response > 4) { return clockwiseColor; }
        if (response > 0) { return this.pickHex(clockwiseColor, noMovementColor, response / 4); }
        if (response < 0) { return this.pickHex(anticlockwiseColor, noMovementColor, response / -4); }
        return noMovementColor;
    }

    getCellBackgroundColorHex(response) {
        var rgbCol = this.getCellBackgroundColor(response);

        var r = rgbCol[0].toString(16);
        var g = rgbCol[1].toString(16);
        var b = rgbCol[2].toString(16);

        if (r.length === 1)
            r = "0" + r;
        if (g.length === 1)
            g = "0" + g;
        if (b.length === 1)
            b = "0" + b;

        return "#" + r + g + b;
    }

    renderLoaded() {
        return (
            <div className="panel-super-wide">
                <div className="panel-header">
                    WINDING GEAR RESPONSE TO WIND
                </div>
                <div className="panel-body">
                    <div className="panel-body-light">
                        <h1>Mean rotation (degrees) over 10 Minutes, based on Wind Speed and Angle of Attack</h1>
                        <table id="response-to-wind-table">
                            <thead>
                                <tr>
                                    <th colSpan={3} align="center"><b>WIND SPEED</b></th>
                                    <th colSpan={36} align="center"><b>WIND ANGLE OF ATTACK</b></th>
                                </tr>
                                <tr>
                                    <td colSpan={3}></td>
                                    <td colSpan={2} align="left"><b>TAIL</b></td>
                                    <td colSpan={1}></td>
                                    <td colSpan={3} align="center"><b>TAIL LEFT</b></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={2} align="center"><b>LEFT</b></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={3} align="center"><b>HEAD LEFT</b></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={2} align="center"><b>HEAD</b></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={3} align="center"><b>HEAD RIGHT</b></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={2} align="center"><b>RIGHT</b></td>
                                    <td colSpan={2}></td>
                                    <td colSpan={3} align="center"><b>TAIL RIGHT</b></td>
                                    <td colSpan={1}></td>
                                    <td colSpan={2} align="right"><b>TAIL</b></td>
                                </tr>

                                <tr>
                                    <td align="center"><b>m/s</b></td>
                                    <td align="center"><b>mph</b></td>
                                    <td align="center"><b>Beaufort</b></td>
                                    {responseToWindAnalysis.responseByWindSpeed[0].responseByAngleOfAttack.map(item => (
                                        <td align="right" width="40">{item.angleOfAttackLowerBound}&deg;<br />to<br />{item.angleOfAttackUpperBound}&deg;</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {responseToWindAnalysis.responseByWindSpeed.map(item => (
                                    <tr>
                                        <td width="60" align="center">{item.windSpeedRangeMsMin} - {item.windSpeedRangeMsMax}</td>
                                        <td width="60" align="center">{item.windSpeedRangeMphMin} - {item.windSpeedRangeMphMax}</td>
                                        <td width="60" align="center"  style={{backgroundColor: beaufortColorScale[item.windSpeedRangeBeaufMin]}}>{item.windSpeedRangeBeaufMin}</td>
                                        {item.responseByAngleOfAttack.map(aoaItem => (
                                            (aoaItem.numberOfObservations > 0) ?
                                                <>
                                                    <td align="right" id={"cell-S" + item.windSpeedRangeMin + "A" + aoaItem.angleOfAttackLowerBound} style={{ backgroundColor: this.getCellBackgroundColorHex(aoaItem.meanResponse) }}>{Math.round(aoaItem.meanResponse * -10) / 10}
                                                    </td>
                                                </> :
                                                <td></td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="panel-columns">
                        <div className="panel-column">
                            <b>Calculated at: {moment(responseToWindAnalysis.analysisUtcTime).format("DD/MM/yyyy HH:mm:ss")}</b><br />
                Based on {responseToWindAnalysis.summaryHistoryEntriesUsed} measurements of winding movement over 10 minute intervals, taken between {moment(responseToWindAnalysis.sourceDataStartUtcTime).format("DD/MM/yyyy HH:mm:ss")} and {moment(responseToWindAnalysis.sourceDataEndUtcTime).format("DD/MM/yyyy HH:mm:ss")}<br />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default ResponseToWindPanel
