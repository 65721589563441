import React from 'react';
import './WhirlOffFeaturedMill.css';
import WhirlOffWeekdaySparkBars from '../WhirlOffWeekdaySparkBars/WhirlOffWeekdaySparkBars';
import Odometer from 'react-odometerjs';
import UnitLocalisationLogic from '../../components/unitlocalisationLogic';
import UnitsContext from '../../UnitsContext';
import WindDirectionArrow from '../../components/weatherForecastTable/windDirectionArrow';
import SailRotationDiagramDutch from '../../components/sailRotationDiagram/sailRotationDiagramDutch';
import { FormattedMessage } from 'react-intl';

class WhirlOffFeaturedMillInfoPanel extends React.Component {

    static contextType = UnitsContext;

    componentDidMount() {
    }

    render() {
        if (this.props.millToDisplay == null) {
            return "";
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var millToDisplay = this.props.millToDisplay;

        var purposeTextLocalisationKey = "whirloff.featuredMill.type.cornMill";
        var purposeTextDefault = "Corn mill";
        var millWebsiteUrl = "";

        var localisedWindSpeed = new UnitLocalisationLogic().getLocalisedWindSpeed(millToDisplay.latestWeatherObservation.windSpeedMs, this.context);
        var windSpeedValue = localisedWindSpeed.value;
        var windSpeedUnits = localisedWindSpeed.units;

        var localisedRotationSpeed = new UnitLocalisationLogic().getLocalisedSailRotationSpeed(millToDisplay.currentSpeedRpm, this.context);
        var sailSpeedValue = localisedRotationSpeed.value;
        var sailSpeedUnits = localisedRotationSpeed.units;

        if (millToDisplay.info !== null) {
            if (millToDisplay.info.purpose !== null) {
                if (millToDisplay.info.purpose === "DRAINAGE") { purposeTextLocalisationKey = "whirloff.featuredMill.type.drainageMill"; purposeTextDefault = "Drainage mill"; }
                if (millToDisplay.info.purpose === "SAW") { purposeTextLocalisationKey = "whirloff.featuredMill.type.sawMill"; purposeTextDefault = "Saw mill"; }
                if (millToDisplay.info.purpose === "POWDER") { purposeTextLocalisationKey = "whirloff.featuredMill.type.powderMill"; purposeTextDefault = "Powder mill"; }
            }

            if (millToDisplay.info.websiteHyperlink != null && millToDisplay.info.websiteHyperlink.url) {
                millWebsiteUrl = millToDisplay.info.websiteHyperlink.url;
            }
        }

        return (
            <>

                {millToDisplay.currentSpeedRpm > 0 ? <div className="whirloff-featured-mill-header-turning" style={{ backgroundColor: this.props.highlightColor }}><FormattedMessage
                    id="whirloff.featuredMill.turningTitle"
                    defaultMessage="TURNING NOW AT {speed} {speedUnits}"
                    values={{ "speed": sailSpeedValue, "speedUnits": sailSpeedUnits }}
                /></div>
                    : <div className="whirloff-featured-mill-header-not-turning" style={{ backgroundColor: this.props.highlightColor }}><FormattedMessage
                        id="whirloff.featuredMill.notTurningTitle"
                        defaultMessage="FEATURED MILL" /> </div>}

                <div className="whirloff-featured-mill-name-panel">
                    <div className="whirloff-featured-mill-name-panel-left-graphic">
                        <SailRotationDiagramDutch currentSpeedRpm={millToDisplay.currentSpeedRpm} windmillSize={90} />
                    </div>
                    <div className="whirloff-featured-mill-name-panel-left">
                        <div className="whirloff-featured-mill-name">{millToDisplay.name}</div>
                        {millToDisplay.city}{millToDisplay.county}&nbsp;<br />
                        <FormattedMessage
                            id={purposeTextLocalisationKey}
                            defaultMessage={purposeTextDefault} /><a href={millWebsiteUrl} target="_blank" rel="noreferrer"><FormattedMessage
                                id="whirloff.featuredMill.website"
                                defaultMessage="Website" /></a>
                    </div>
                    <div className="whirloff-featured-mill-name-panel-right">
                        <img src={"/img/flags/" + millToDisplay.country.toLowerCase() + ".png"} width="50" style={{ "verticalAlign": "middle" }} title={"Flag"} alt={"Flag"}></img>
                    </div>

                </div>

                <div className="whirloff-featured-mill-stats-row">
                    <div className="whirloff-featured-mill-stats-left">
                        <div className="whirloff-featured-mill-stats-title"><FormattedMessage
                            id="whirloff.featuredMill.stats.turnsThisYear"
                            defaultMessage="Turns this year" /></div>
                        <div className="whirloff-featured-mill-stats-content">
                            <div className="whirloff-featured-mill-rotation-count">{millToDisplay.currentSpeedRpm > 0 ? <><Odometer value={millToDisplay.revCountThisYear} format="(,ddd)" theme="minimal" /></> : <>{millToDisplay.revCountThisYear.toLocaleString()}</>}</div>
                        </div>
                    </div>
                    <div className="whirloff-featured-mill-stats-right">
                        <div className="whirloff-featured-mill-stats-title"><FormattedMessage
                            id="whirloff.featuredMill.stats.rank"
                            defaultMessage="Rank" /></div>
                        <div className="whirloff-featured-mill-stats-content">
                            <div className="whirloff-featured-mill-rotation-count">{millToDisplay.rank}</div>&nbsp;/&nbsp;{this.props.whirloff.length}
                        </div>
                    </div>
                </div>

                <div className="whirloff-featured-mill-stats-row">
                    <div className="whirloff-featured-mill-stats-left">
                        <div className="whirloff-featured-mill-stats-title"><FormattedMessage
                            id="whirloff.featuredMill.stats.weekdays"
                            defaultMessage="Weekdays" /></div>
                        <div className="whirloff-featured-mill-stats-content">
                            <WhirlOffWeekdaySparkBars countsByWeekday={millToDisplay.annualStats.rotationCountsByDayOfWeek} barWidth={this.props.mode === "mobile" ? 18 : 24} barHeight={48} />
                        </div>
                    </div>

                    <div className="whirloff-featured-mill-stats-right">
                        <div className="whirloff-featured-mill-stats-title"><FormattedMessage
                            id="whirloff.featuredMill.stats.wind"
                            defaultMessage="Wind" /></div>
                        <div className="whirloff-featured-mill-stats-content">
                            {millToDisplay.latestWeatherObservation != null && <>
                                <div>
                                    {windSpeedValue}&nbsp;
                                    <FormattedMessage
                                        id={"common.speed." + windSpeedUnits}
                                        defaultMessage={windSpeedUnits} />
                                    <br /><div className="whirloff-featured-mill-wind-direction-compass-point">
                                        <FormattedMessage
                                            id={"common.compassPoint." + millToDisplay.latestWeatherObservation.windDirectionCompassPoint}
                                            defaultMessage={millToDisplay.latestWeatherObservation.windDirectionCompassPoint} />
                                    </div>
                                </div>
                                <div className="whirloff-featured-mill-wind-direction-arrow">
                                    <WindDirectionArrow size="60" windDirection={millToDisplay.latestWeatherObservation.windDirection} className="whirloff-featured-mill-wind-direction-arrow" />
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                <div className="whirloff-featured-mill-data-source">
                    {millToDisplay.dataProvider === "SPINNINGMILLS" ? <><FormattedMessage
                        id="whirloff.featuredMill.dataFrom"
                        defaultMessage="Data from" />&nbsp;<a href="https://draaiendemolens.nl">draaiendemolens.nl</a></> : <>Data from&nbsp;<a href={"https://www.smartmolen.com/" + millToDisplay.shortName}>smartmolen.com</a></>}
                </div>
            </>
        )
    }
}

export default WhirlOffFeaturedMillInfoPanel