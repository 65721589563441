import React from 'react';
import './WhirlOffHeader.css';
import SailRotationDiagramDutch from '../../components/sailRotationDiagram/sailRotationDiagramDutch';
import { FormattedMessage } from 'react-intl';

class WhirloffHeader extends React.Component {

    componentDidMount() {
    }

    render() {

        var totalSpeed = this.props.whirloff.reduce((n, { currentSpeedRpm }) => n + currentSpeedRpm, 0);

        return (
            <>
                <div className="whirloff-header-container" style={{ backgroundColor: this.props.highlightColor }}>
                    <div className="whirloff-header-logo">
                        <SailRotationDiagramDutch currentSpeedRpm={totalSpeed} windmillSize={this.props.windmillSize} />
                    </div>
                    {this.props.layout === "xlarge" && <>
                        <div className="whirloff-header-right">
                            <b><FormattedMessage
                                id="whirloff.title"
                                defaultMessage="Windmill Whirl-Off" /></b>
                            <div className="whirloff-header-beta"><FormattedMessage
                                id="whirloff.beta"
                                defaultMessage="BETA" /></div>
                            <div className="whirloff-header-data-sources">
                                <br />
                                <FormattedMessage
                                    id="whirloff.header.featuringDataFrom"
                                    defaultMessage="Featuring data from" /><br />
                                <div className="whirloff-header-links">
                                    <div className="whirloff-header-link"><a href="https://draaiendemolens.nl">draaiendemolens.nl</a></div>&nbsp;&nbsp;
                                    <div className="whirloff-header-link"><a href="https://www.smartmolen.com">smartmolen.com</a></div>
                                </div>
                            </div>
                        </div>
                    </>
                    }

                    {this.props.layout === "tablet" && <>
                        <div className="whirloff-header-right-tablet">
                            <div className="whirloff-header-right-tablet-heading">
                                <b>Windmill Whirl-Off</b>
                                <div className="whirloff-header-beta"><FormattedMessage
                                id="whirloff.beta"
                                defaultMessage="BETA" /></div>
                            </div>
                            <div className="whirloff-header-right-tablet-data-sources">
                                <div className="whirloff-header-data-sources">
                                    <br />
                                    <FormattedMessage
                                    id="whirloff.featuringDataFrom"
                                    defaultMessage="Featuring data from" /><br /><br />
                                    <div className="whirloff-header-links">
                                        <div className="whirloff-header-link"><a href="https://draaiendemolens.nl">draaiendemolens.nl</a></div>&nbsp;&nbsp;
                                        <div className="whirloff-header-link"><a href="https://www.smartmolen.com">smartmolen.com</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }

                    {this.props.layout === "mobile" && <>
                        <div className="whirloff-header-mobile">
                            <b>Windmill Whirl-Off</b>
                            <div className="whirloff-header-beta">BETA</div>
                        </div>
                    </>
                    }


                </div>
            </>
        )
    }
}

export default WhirloffHeader