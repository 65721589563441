import React from 'react'
import './WhirlOff2.css'
import WhirlOffFooter from './WhirlOffFooter/WhirlOffFooter';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Breakpoint, BreakpointProvider } from 'react-socks';
import WhirlOffMolenList from './WhirlOffMolenList/WhirlOffMolenList';
import WhirlOffHeader from './WhirlOffHeader/WhirlOffHeader'
import WhirlOffHeadlineStats from './WhirlOffHeadlineStats/WhirlOffHeadlineStats'
import WhirlOffFeaturedMill from './WhirlOffFeaturedMill/WhirlOffFeaturedMill';
import WhirlOffFeaturedMillImage from './WhirlOffFeaturedMill/WhirlOffFeaturedMillImage';
import WhirlOffFeaturedMillInfoPanel from './WhirlOffFeaturedMill/WhirlOffFeaturedMillInfoPanel';
import { FormattedMessage } from 'react-intl';

class WhirlOff2 extends React.Component {

  constructor(props) {
    super();
    this.onMapMillSelect = this.onMapMillSelect.bind(this);
  }

  state = {
    onClickBackButton: false,
    mapZoom: 6,
    mapLat: 52.8,
    mapLong: -1.5,
    selectedMolen: "upminster",
    mapMolenFocus: "",
    name: null,
    displaySequence: 0
  };

  reloadRequested = false;
  lastLogTimeMillis = new Date().getTime();
  lastReloadTimeMillis = 0;

  onMapMillSelect(entry) {
    try {
      var smartmolenSelectionInstruction = JSON.parse(entry.data);
      console.log("map mill select " + smartmolenSelectionInstruction.selectedMolen);

      if (typeof (smartmolenSelectionInstruction.selectedMolen) !== 'undefined') {
        this.setState({ selectedMolen: smartmolenSelectionInstruction.selectedMolen });
      }
    }
    catch (e) {
      // do nothing - miscellaneous messages may be picked up which will fail JSON parse
    }
  }


  componentDidMount() {

    // these lines are part of the logic to reload the page when the back button is pressed
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onClickBackButton);

    window.addEventListener("message", this.onMapMillSelect);

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('SignalR Connection started from WhirlOff2');
        })
        .catch(err => {
          console.log('Error while establishing SignalR connection from WhirlOff2 :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadState();
        }
      );

    });

    this.reloadState();

    setInterval(() => {
      this.setState(prevState => {
        return {
          ...prevState,
          displaySequence: (prevState.displaySequence + 1),
        };
      });
    }, 30000);

  }

  reloadState() {
    this.reloadRequested = false;
    if (this.lastReloadTimeMillis <= (new Date().getTime()) - 10000) {
      var { year } = this.props.match.params
      if (year == null) { year = new Date().getFullYear(); }
      fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/whirloff/${year}`)
        .then(response => response.json())
        .then(response => {
          this.setState({
            whirloff: response,
            year: year
          })
        });
      this.lastLogTimeMillis = new Date().getTime();
    }

    // log in 'plausible' that the window is still open (every 30 mins)
    if (this.lastLogTimeMillis <= (new Date().getTime()) - 1800000) {
      window.plausible('whirloff');
      this.lastLogTimeMillis = new Date().getTime();
    }

  }

  // this function is part of the logic to reload the page when the back button is pressed
  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.onClickBackButton) {

      this.onClickBackButton = true;
      this.reloadRequested = true;
      this.reloadState();
      window.history.push();
    }
  }

  // this function is part of the logic to reload the page when the back button is pressed
  componentWillUnmount = () => {
    window.removeEventListener('popstate', this.onClickBackButton);
    clearInterval(this.state.displaySequenceIntervalId);
  }



  focusMapOnMolen(molen) {
    this.setState({ "selectedMolen": molen.shortName });
    document.getElementById('windy-iframe').contentWindow.postMessage({ 'messageType': 'focus', 'molenShortName': molen.shortName });
  }

  setMapBounds(bounds) {
    document.getElementById('windy-iframe').contentWindow.postMessage({ 'messageType': 'bounds', 'bounds': bounds });
  }

  render() {
    if (this.state == null || this.state.whirloff == null) {
      return (
        <div className="whirloff2">
          Loading ...
        </div>
      )
    }
    else {
      document.title = "smartmolen Whirl-Off " + this.state.year;
      return this.renderLoaded();
    }
  }

  renderLoaded() {

    var highlightColor = "#109010";
    var millsToFeature = this.state.whirloff.filter(e => e.currentSpeedRpm > 0);

    if (millsToFeature.length === 0) {
      millsToFeature = this.state.whirloff;
      highlightColor = "#644109";
    }

    var millToDisplay = millsToFeature[this.state.displaySequence % millsToFeature.length];

    return (
      <>
        <BreakpointProvider>
          <Breakpoint xxlarge up>
            <div className="whirloff2-full">
              <div className="whirloff2-header-full"><WhirlOffHeader whirloff={this.state.whirloff} year={this.state.year} windmillSize={185} layout="xlarge" highlightColor={highlightColor} /></div>
              <div className="whirloff2-sub-header-full"><FormattedMessage
                id="whirloff.subtitle.line1"
                defaultMessage="A fun and friendly international celebration of windmill sail rotation ..." /><br /><FormattedMessage
                  id="whirloff.subtitle.line2"
                  defaultMessage="... because keeping windmills turning is keeping heritage alive." /></div>
              <div className="whirloff2-molen-list-full"><WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} showWeekdayDistribution={false} flagWidth={26} showWind={false} /></div>
              <div className="whirloff2-footer-full"><WhirlOffFooter /></div>
              <div className="whirloff2-map-full">
                <div className="whirloff2-windy-map-full">
                  <iframe id="windy-iframe" src={"/map/map.html?mode=whirloff&molenFocus=" + this.state.mapMolenFocus + "&year=" + this.state.year} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              </div>
              <div className="whirloff2-headline-stats-full"><WhirlOffHeadlineStats whirloff={this.state.whirloff} year={this.state.year} mode="long" /></div>
              <div className="whirloff2-featured-mill-full" style={{ backgroundColor: highlightColor }}><WhirlOffFeaturedMill millToDisplay={millToDisplay} whirloff={this.state.whirloff} highlightColor={highlightColor} /></div>
            </div>
          </Breakpoint>

          <Breakpoint xlarge only>
            <div className="whirloff2-large">
              <div className="whirloff2-header-large"><WhirlOffHeader whirloff={this.state.whirloff} year={this.state.year} windmillSize={105} layout="tablet" highlightColor={highlightColor} /></div>
              <div className="whirloff2-molen-list-large"><WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} showWeekdayDistribution={false} flagWidth={26} showWind={false} /></div>
              <div className="whirloff2-map-large"><iframe id="windy-iframe" src={"/map/map.html?mode=whirloff&molenFocus=" + this.state.mapMolenFocus + "&year=" + this.state.year} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              <div className="whirloff2-headline-stats-large"><WhirlOffHeadlineStats whirloff={this.state.whirloff} year={this.state.year} /></div>
              <div className="whirloff2-featured-mill-large" style={{ backgroundColor: highlightColor }}><WhirlOffFeaturedMill millToDisplay={millToDisplay} whirloff={this.state.whirloff} highlightColor={highlightColor} /></div>
              <div className="whirloff2-footer-large"><WhirlOffFooter /></div>
            </div>
          </Breakpoint>


          <Breakpoint large only>
            <div className="whirloff2-large">
              <div className="whirloff2-header-large"><WhirlOffHeader whirloff={this.state.whirloff} year={this.state.year} windmillSize={105} layout="tablet" highlightColor={highlightColor} /></div>
              <div className="whirloff2-molen-list-large"><WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} showWeekdayDistribution={false} flagWidth={26} showWind={false} /></div>
              <div className="whirloff2-map-large"><iframe id="windy-iframe" src={"/map/map.html?mode=whirloff&molenFocus=" + this.state.mapMolenFocus + "&year=" + this.state.year} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              <div className="whirloff2-headline-stats-large"><WhirlOffHeadlineStats whirloff={this.state.whirloff} year={this.state.year} /></div>
              <div className="whirloff2-featured-mill-large" style={{ backgroundColor: highlightColor }}><WhirlOffFeaturedMill millToDisplay={millToDisplay} whirloff={this.state.whirloff} highlightColor={highlightColor} /></div>
              <div className="whirloff2-footer-large"><WhirlOffFooter /></div>
            </div>
          </Breakpoint>

          <Breakpoint medium down>
            <div className="whirloff2-mobile">
              <div className="whirloff2-header-mobile"><WhirlOffHeader whirloff={this.state.whirloff} year={this.state.year} windmillSize={78} highlightColor={highlightColor} layout="mobile" /></div>
              <div className="whirloff2-molen-list-mobile">
                <WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} flagWidth={24} showWind={false} />
              </div>
              <div className="whirloff2-headline-stats-mobile"><WhirlOffHeadlineStats whirloff={this.state.whirloff} year={this.state.year} mode="short" /></div>
              <div className="whirloff2-map-mobile"><iframe id="windy-iframe" src={"/map/map.html?mode=whirloff&molenFocus=" + this.state.mapMolenFocus + "&year=" + this.state.year} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              <div className="whirloff2-featured-mill-mobile-info-container" style={{ backgroundColor: highlightColor }}>
                <div className="whirloff2-featured-mill-mobile-info-panel"><WhirlOffFeaturedMillInfoPanel millToDisplay={millToDisplay} whirloff={this.state.whirloff} mode="mobile" /></div>
              </div>
              <div className="whirloff2-featured-mill-mobile-image"><WhirlOffFeaturedMillImage millToDisplay={millToDisplay} /></div>
              <div className="whirloff2-footer-mobile"><WhirlOffFooter /></div>
            </div>
          </Breakpoint>
        </BreakpointProvider >
      </>
    )
  }
}



export default WhirlOff2