import React from 'react';
import UnitsContext from '../../UnitsContext';
import UnitLocalisationLogic from '../../components/unitlocalisationLogic';

class WhirlOffWeekdaySparkBars extends React.Component {

    static contextType = UnitsContext;

    componentDidMount() {
    }

    render() {

        var barWidth = (this.props.barWidth == null) ? 11 : this.props.barWidth;
        var barSpacing = (this.props.barWidth == null) ? 11 : this.props.barWidth * 1.1;
        var height = (this.props.barHeight == null) ? 20 : this.props.barHeight;

        var fontSize = barWidth * 0.8;

        var dayOfWeekLetters = new UnitLocalisationLogic().getDaysOfWeekFirstLetters(this.context);

        // NOTE co-ordinates 0,0 start from top left corner

        var maxValue = Math.max(...this.props.countsByWeekday);
        var scaledArray;
        if (maxValue === 0) { scaledArray = this.props.countsByWeekday.map(elem => height); } else {
            scaledArray = this.props.countsByWeekday.map(elem => height - ((elem * height) / maxValue));
        }

        return (
            <>
                <div className="whirloff-weekday-spark-bars" >
                    <svg height={height} width={(barSpacing * 6) + barWidth}>
                        {scaledArray.map((currentValue, index) => (
                            <>
                                <polygon points={(index * barSpacing).toString() + "," + height + " " + (index * barSpacing + barWidth) + "," + height + " " + (index * barSpacing + barWidth) + "," + currentValue + " " + (index * barSpacing) + "," + currentValue} fill={index <= 4 ? "#707070" : "#189018"} />
                                <text x={(index * barSpacing) + (barWidth / 2)} y={height - (fontSize / 2)} fill="#e0e0e0" dominantBaseline="middle" textAnchor="middle" fontSize={fontSize}>{dayOfWeekLetters[index]}</text>
                            </>
                        ))}
                    </svg>
                </div>
            </>
        )
    }
}



export default WhirlOffWeekdaySparkBars
