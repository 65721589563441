import React from 'react';
import './WhirlOffFooter.css';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

class WhirlOffFooter extends React.Component {

  componentDidMount() {
  }

  render() {

    return (
      <>
        <div className="whirloff-footer-container">
          <div>
            Operated by the<br /><b>Friends of Upminster Windmill</b><br />
            <div className="whirloff-footer-copyright">
              &copy; {moment().format('YYYY')} smartmolen.com<br />
            </div>
            <div className="whirloff-footer-terms">
              <a href="/docs/210921FUWPrivacyNotice.pdf"><FormattedMessage
                id="whirloff.footer.privacyPolicy"
                defaultMessage="Privacy Policy" /></a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/docs/210921SmartMolenWebsiteTerms.pdf"><FormattedMessage
                  id="whirloff.footer.termsOfUse"
                  defaultMessage="Terms of Use" /></a>
            </div>
          </div>
          <img src="/img/LogoUpminsterLightSmall.jpg" className="whirloff-footer-fuw-logo" alt=""></img>
        </div>
      </>
    )
  }
}

export default WhirlOffFooter